@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i|Libre+Franklin:400,400i,700,700i");
@import '~react-datepicker/dist/react-datepicker.css';
.color-leaf {
  color: #34da96;
}

.bg-leaf {
  background-color: #34da96;
}

.color-space {
  color: #1E234D;
}

.bg-space {
  background-color: #1E234D;
}

.color-ice {
  color: #F3F3F7;
}

.bg-ice {
  background-color: #F3F3F7;
}

.color-blood {
  color: #831d1d;
}

.bg-blood {
  background-color: #831d1d;
}

.color-white {
  color: #FFFFFF;
}

.bg-white {
  background-color: #FFFFFF;
}

.color-lightgrey {
  color: #A4ADB8;
}

.bg-lightgrey {
  background-color: #A4ADB8;
}

.color-darkgrey {
  color: #52575e;
}

.bg-darkgrey {
  background-color: #52575e;
}

.color-black {
  color: #000000;
}

.bg-black {
  background-color: #000000;
}

button {
  text-transform: uppercase;
}

body {
  min-height: 100vh;
  min-width: 320px;
  background: #f3f3f7;
  color: #52575e;
  font-family: "Libre Franklin", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.btn {
  font-weight: 700;
  text-transform: uppercase;
}

.btn-primary {
  color: #f3f3f7;
  background-color: #34da96;
  border-color: #34da96;
}
.btn-primary.disabled, .btn-primary:disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.25;
  color: #f3f3f7;
  background-color: #34da96;
  border-color: #34da96;
}

.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #f3f3f7;
  background-color: #1a8e5f;
  border-color: #1a8e5f;
}

.btn-outline-primary {
  color: #34da96;
  border-color: #34da96;
}

.btn-outline-primary:hover {
  background-color: #34da96;
  border-color: #34da96;
}

@media (min-width: 1440px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1380px;
  }
}
code {
  color: #34da96;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 700;
}

h1 {
  font-size: 2rem;
}
@media (min-width: 575px) {
  h1 {
    font-size: 2.5rem;
  }
}

a {
  font-weight: 700;
}

a[href^="http://"]::after,
a[href^="https://"]::after {
  content: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBmaWxsPSIjY2NjIiBkPSJNNDQ4IDgwdjM1MmMwIDI2LjUxLTIxLjQ5IDQ4LTQ4IDQ4SDQ4Yy0yNi41MSAwLTQ4LTIxLjQ5LTQ4LTQ4VjgwYzAtMjYuNTEgMjEuNDktNDggNDgtNDhoMzUyYzI2LjUxIDAgNDggMjEuNDkgNDggNDh6bS04OCAxNkgyNDguMDI5Yy0yMS4zMTMgMC0zMi4wOCAyNS44NjEtMTYuOTcxIDQwLjk3MWwzMS45ODQgMzEuOTg3TDY3LjUxNSAzNjQuNDg1Yy00LjY4NiA0LjY4Ni00LjY4NiAxMi4yODQgMCAxNi45NzFsMzEuMDI5IDMxLjAyOWM0LjY4NyA0LjY4NiAxMi4yODUgNC42ODYgMTYuOTcxIDBsMTk1LjUyNi0xOTUuNTI2IDMxLjk4OCAzMS45OTFDMzU4LjA1OCAyNjMuOTc3IDM4NCAyNTMuNDI1IDM4NCAyMzEuOTc5VjEyMGMwLTEzLjI1NS0xMC43NDUtMjQtMjQtMjR6Ij48L3BhdGg+PC9zdmc+Cg==");
  position: relative;
  top: -0.35em;
  display: inline-block;
  left: 0.25em;
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.3rem;
}
a[href^="http://"].no-link-decoration::after,
a[href^="https://"].no-link-decoration::after {
  content: none;
}

input[type=radio] + label, input[type=checkbox] + label {
  display: block;
  cursor: pointer;
  line-height: 1.45em;
  margin-bottom: 1rem;
  font-weight: normal;
}

input[type=radio] + label::before, input[type=checkbox] + label::before {
  content: "";
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #34da96;
  display: inline-block;
  width: 1.45em;
  height: 1.45em;
  padding: 0.5em;
  margin-right: 0.5rem;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}

input[type=checkbox] + label::before {
  background-size: 80%;
  border-radius: 0.2em;
}

input[type=radio] + label::before {
  background-size: 48%;
  border-radius: 50%;
}

input[type=radio] + label:active::before, input[type=checkbox] + label:active::before {
  transform: scale(0.9);
}

input[type=radio]:checked + label::before, input[type=checkbox]:checked + label::before {
  background-color: #34da96;
  border-color: #34da96;
  color: #fff;
}

input[type=checkbox]:checked + label::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M20.285 2.5L9 14.067 3.714 9.056 0 12.772 9 21.5 24 6.215z" fill="%23f3f3f7"/></svg>');
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  input[type=checkbox]:checked + label::before {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEsAAABLCAYAAAA4TnrqAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAuIwAALiMBeKU/dgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAOMSURBVHic7dz7q+RjAMfx79mDZY+WLbfc2pXLuoSs1KbNpU1EUrKFtqT8sPEDSUqKX0SUthBJbEqStkitNgqhpP3JZRXZWut2NqzL7mKdc15++M63nbNnZs5cnmee2Znn/Qc8n8/zbpr5zvRpiiKTyWQymUwm0w44InWHgwJcje9wXuouAw2uwt9KJrOwJmA19ppNFnYguBR/aUwWVoGV+LOJqCysAhfht3lEZWG4EL+2KWp0heF8/NKhqNEThrPwU5eiRkcYzsCPPYoafmE4Hd8HEjVL2Hjqy4UEy4qieK8oilMCHz1RFMU5gc9MB07Gt4FfURVf48TUdwwCjsdXkUR9g5NS3zEIOA5fRhK1HUtT3zEIOBafRxS1LPUdg4CjsSWSqB04LfUdg4Cj8GkkUT9jeeo7BgGL8UkkUZMYjkcELMIHEUWdG6roGO7B4iAHdp6/CO9HErVTqK80NVFP1w7egmOCHNx+/kJsiiRqF1aEKlovqmKrPj2o4TC8FUnU77g4VNFGoiq2ifzxikPxZkRRl4QqOoan5gncjjODBM7NH8erkUTtxqpQRdsRVTGJC4IE788fxyuRRO3BZaGKdiKqYhdWBspfgJcDC6rYgytC9OxWVMVurA6Q/1wgMQeyF1cOgqiKf3BDD/nP9JjfjH9x7SCJqi92UxcdHg+U36jPdYMoqmIKt3fQ4dHA+RX7cH0QUbWizZ6jemUGd7WR/0ik/H26fEtoVfYOTEcqDI+1yH44UuYUbg4qqq70LfgvUnEaCMO9kbKmcGsUUXXl1yhfurF4FgtqWXdHypjG2qii6oTdqPz0iMUGpaiZCGdP47a+iKoTdo39E8KDhRms66uoOmGXa76QGzRmcGcSUXXCVpl/KTcI3JdUVIVyg/lHahstuD+1o1lghe4HYTF5ILWbhiinhjtT26njwdROWoKz8UNqS3gotYu2UE4PdyQU9URqBx2BpeLtoFrxZOq7dwVOVe6W+sX61HfuCZyAL/og6nmMpb5vzygXd59FFPXCUIiqwBJx5j8vqv1KMVQoh2UhZ0AbhlJUhXJg9nEAUa/hkNT3iQ4m8G4Pol4fCVEVyh3V5i5EbRwpURXKmdAbHYjahIWpeyejJmxjG6LeHmlRFco1TKuRx2YcnrrnwFAT9lIDUe/If0IxF3MXhB9iInWvgaUmbD0+wpGp+ww8NWH5PSqTyWQymcwo8D9kgEfPZkzqHAAAAABJRU5ErkJggg==");
  }
}

input[type=radio]:checked + label::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle fill="%23f3f3f7" cx="12" cy="12" r="12"/></svg>');
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  input[type=radio]:checked + label::before {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEsAAABLCAYAAAA4TnrqAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAuIwAALiMBeKU/dgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAASpSURBVHic7ZxNaB1VFMd/Z0KtmKQKFXHVVNom4koTg124adPixiBdCUJri7TVhbh049fGL8QPREERF1o3rXRR7LJGEFS0SQUX1tQm0WwUtBDMewomr38X9728D9+8eXl5M3cmb34wi8zMu3PuP/feuXPvOcfwgKQ7gDFgGLgT2A3cBtwE9APbgL+AYvn4A/gZmAWuADNmtpC03ZbEQyQNApPAAWAfsLMLxS4AXwAXgPNmttyFMv0gqU/SA5I+kVRUvBQknSo/L/Bd97aRtEXSEUk/xSxQGPOSnpJ0o28tQpEUSDopadGTSI38Kum40tbSJN0j6Ru/2oQyI+k+3xohaauktySV/OoRyaqkNyRt3Uh9O34bShoCTgP+/2vtcwl42MyudvLjjvqzpEPAD2RLKIBRYFrSQ538eN1iSToKnMFNHLPIzcBZSSdjfYqkp/0NO7HwSlxCPe+7ZjHxbLsatDXAyzXZ9zpWOv08aWbvRN0UKZbcYP4p0NcNq1JKCThkZp+1uqmlWJJ2ATO4QXGzswSMmdl82A2hb0O5CdwZekMogFuA02oxcW01dXgVNy/pJe4FXgq72LQbShoDvmVzj1NhlIBxM/u+8cL/WpbcV/q79KZQ4Or9vpqsVjTrhsfJ3mdMtxkHjjWerOuGkrbg1rqHEjIqzSwCe8zs38qJxpZ1hFyoCjuAR2pPrLUsSX3AZWBPwkalmVngLjO7DvUt6yC5UI2MABOVP2rFOpy8LZlgTRcDkLQN+A23yZlTTxG43cwKlZY1SS5UGP3Ag1DthhPh9+YA+6Eq1j6PhmSB/QAm56QRuiyRs8bOAOfNkhPNaICbS+REM5KL1T4jAfmsvV2GA2C7bysywvYAGPRtRUYYDIAB31ZkhEGTtErvLiGvh1IA/OPbiozwdwAUfFuREZYDILsu0cmyHADXfFuREa4FuN2cnGiuBLhF+ZxoZnOx2mc2X89qn6GgHF31i29LUs5VM1usLCtPeTUl/UxBdQ3+gkdDssAUVPcN+4HfyT+qm1G/b2hmReCcX5tSy1kzK0D99v0pT8aknTVdGr1ofsTFLec4mnvRmFkJ53SbU+XlilCQe/61YhHYbWYrlRN1nn/lCy8mbVVKeaFWKGji2l320v0K2JuUVSnkIrC3tgtCuB/8KPAdvbk2374fPICZXQLejtuqlPJmM6GgRaBTebD/kt7qjheB+2vduWvJo8KqLAGjrXLctIyRNrM54FFcP97MrAKHo5IBRQaUm9k54IluWZVCBDxuZuejbmwr+t7MPgCe26hVKeUZM/uw66XKRd9fTzDYO27iib6vEeyopBXftdwgq5JOrLfuHaVXkTSJW7rI4ltyCTeYR45RjWwkF80OXC6aLM3DZnC5aOYSf7JclqPXy806zaxIek3SDYmL1ES0uyV97VePUKYljfvWqA65zGyPSVrwKk2VeUnHJCWSBLIjVM35d9mTSHOSTsh922YDuZZ2UNLHctke42RZ0keSDijGHH9J5SkdwIWhTeCCqnZ1odg53Obn57g8pcUulNkSL/1ZLgXeGC66Y7h83Irb5B2gmgG3UD7+xGW+rWTAnTazxaTt/g8Jkwg0lsjEtAAAAABJRU5ErkJggg==");
  }
}

input[type=radio]:disabled + label::before, input[type=checkbox]:disabled + label::before {
  cursor: no-drop;
  transform: scale(1);
  border-color: rgba(243, 243, 247, 0.5);
  opacity: 0.5;
}

input[type=radio]:checked:disabled + label::before, input[type=checkbox]:checked:disabled + label::before {
  cursor: no-drop;
  transform: scale(1);
  background-color: rgba(243, 243, 247, 0.5);
  border-color: #f3f3f7;
  opacity: 0.5;
}

/* stylelint-disable no-descending-specificity */
input[type=checkbox] {
  position: absolute !important;
  opacity: 0;
  left: 2em;
}
input[type=radio] {
  position: absolute !important;
  opacity: 0;
  left: 2em;
}
/* stylelint-enable no-descending-specificity */
a {
  text-decoration: none;
}
a:hover {
  color: #34da96;
}

.paymentIcon {
  width: 3.5em;
  height: auto;
  margin-top: 0.25em;
  margin-right: 1em;
}

header.SiteHeader {
  background-color: #1e234d;
}
header.SiteHeader img.logo {
  height: 4rem;
  width: auto;
}
header.SiteHeader button.search {
  position: relative;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  background-color: #34da96;
  color: #f3f3f7;
  border: 1px solid #52575e;
}
header.SiteHeader button.search svg {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 1em;
  height: 1em;
}
header.SiteHeader a.nav-link {
  font-weight: bold;
  color: #f3f3f7 !important;
  margin-left: 1rem;
  margin-right: 1rem;
  transition: all ease-in-out;
}
header.SiteHeader a.nav-link:hover {
  color: #34da96 !important;
}
@media (min-width: 768px) {
  header.SiteHeader .navbar-brand {
    order: -1;
  }
}

footer.SiteFooter {
  background-color: #1e234d;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 6rem;
}
footer.SiteFooter a {
  color: #f3f3f7;
}
footer.SiteFooter img.logo {
  width: 100%;
}
@media (min-width: 992px) {
  footer.SiteFooter img.logo {
    width: 80%;
  }
}
footer.SiteFooter p {
  color: #f3f3f7;
}
footer.SiteFooter h3 {
  color: #34da96;
  margin-bottom: 1rem;
}
footer.SiteFooter .siteBrand {
  color: #f3f3f7;
}
footer.SiteFooter .paymentIcon {
  width: 2.5em;
  height: 100%;
  margin-right: 1em;
}
footer.SiteFooter .foe-footer-links a {
  color: #34da96;
}
footer.SiteFooter hr {
  width: 90%;
  height: 1px;
  background-color: #f3f3f7;
  opacity: 0.2;
}

.Animation {
  position: relative;
  z-index: 2000;
}
.Animation .btn-outline-primary {
  color: #34da96;
}

.AnimationCard {
  font-family: "Libre Baskerville", Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;
  color: #52575e;
  font-size: 0.8rem;
  text-align: center;
}
.AnimationCard .btn {
  transition: opacity 600ms ease-in-out;
}
@media screen and (min-width: 576px) {
  .AnimationCard {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .AnimationCard {
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 1200px) {
  .AnimationCard {
    font-size: 1.2rem;
  }
}
.AnimationCard.no-interaction * {
  cursor: default !important;
  pointer-events: none !important;
  transition: none !important;
}
.AnimationCard .animation-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  perspective: 1200px;
  perspective-origin: 50% 50%;
  transform-style: preserve-3d;
}
.AnimationCard .animation-wrapper * {
  transform-style: preserve-3d;
}
.AnimationCard .envelope-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: camera-movement 120s infinite ease-in-out;
}
.AnimationCard .envelope-wrapper svg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.AnimationCard .card-wrapper {
  pointer-events: none;
  transform: translate3d(0, 0, 0) rotateY(0.5turn);
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 800ms ease-in-out;
}
.AnimationCard .animation-wrapper.started .card-wrapper {
  animation: card-wrapper-movement-in 800ms ease-in-out both;
}
.AnimationCard .card-message {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  backface-visibility: hidden;
  white-space: pre-wrap;
  background-image: linear-gradient(#fefefe, #dedede);
  transform: translate3d(0, 0, 1px);
  border-radius: 0.25rem;
}
.AnimationCard .card-design {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  backface-visibility: hidden;
  border-radius: 0.25rem;
  transform: rotateY(180deg);
}
.AnimationCard .card-design img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.AnimationCard .card-main {
  cursor: pointer;
  transition: transform 400ms ease-in-out, box-shadow 800ms ease-in-out;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
}
.AnimationCard .card-main.isActive {
  transform: rotateY(0.5turn);
}
.AnimationCard .envelope {
  cursor: pointer;
  position: absolute;
  top: 2rem;
  right: 2rem;
  bottom: 2rem;
  left: 2rem;
  transition: transform 600ms 800ms ease-out;
}
.AnimationCard .envelope .envelope-back {
  pointer-events: none;
  transform: translate3d(0, 0, -3px);
  border-radius: 0.5rem;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}
.AnimationCard .envelope .envelope-flap {
  border-radius: 0.5rem;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: transform 500ms 900ms ease-in-out;
  transform: translate3d(0, 0, -4px);
  transform-origin: 50% 0;
}
.AnimationCard .envelope.isActive {
  transform: translate3d(-220%, -150%, -4000px) rotateY(0.5turn);
  transition: transform 600ms ease-out;
}
.AnimationCard .envelope.isActive .card-wrapper {
  animation: card-wrapper-movement-out 800ms 500ms ease-in-out both;
  pointer-events: all;
}
.AnimationCard .envelope.isActive .card-main {
  box-shadow: 0.5rem 0.5rem 2rem rgba(0, 0, 0, 0.5);
  transition: transform 400ms ease-in-out, box-shadow 800ms 600ms ease-in-out;
}
.AnimationCard .envelope.isActive .envelope-flap {
  transform: translate3d(0, 5px, 2px) rotateX(-180deg);
  transition: transform 500ms ease-in-out;
}
.AnimationCard .envelope-front {
  transform: translate3d(0, 0, 3px);
  border-radius: 0.5rem;
  position: absolute;
  background-image: linear-gradient(#34da96, #1a8e5f);
  width: 100%;
  height: 100%;
  box-shadow: 0.25rem 0.25rem 1.5rem rgba(0, 0, 0, 0.25);
  text-align: center;
}
.AnimationCard .envelope-inside {
  pointer-events: none;
  transform: translate3d(0, 0, 2px);
  border-radius: 0.5rem;
  position: absolute;
  background-image: linear-gradient(#1a8e5f, #34da96);
  width: 100%;
  height: 100%;
}
.AnimationCard .envelope-front p {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em;
}
@keyframes camera-movement {
  0% {
    transform: rotate3d(0, 0, 3, 2.5deg);
  }
  10% {
    transform: rotate3d(1, 0, 3, -2.5deg);
  }
  20% {
    transform: rotate3d(0, 1, 3, 2.5deg);
  }
  30% {
    transform: rotate3d(0, 0, 3, -2.5deg);
  }
  40% {
    transform: rotate3d(1, 1, 3, 2.5deg);
  }
  50% {
    transform: rotate3d(1, 0, 3, -2.5deg);
  }
  60% {
    transform: rotate3d(0, 1, 3, 2.5deg);
  }
  70% {
    transform: rotate3d(1, 1, 3, -2.5deg);
  }
  80% {
    transform: rotate3d(1, 0, 3, 2.5deg);
  }
  90% {
    transform: rotate3d(0, 1, 3, -2.5deg);
  }
  100% {
    transform: rotate3d(0, 0, 3, 2.5deg);
  }
}
@keyframes card-wrapper-movement-out {
  0% {
    transform: translate3d(0, 0, 0) scale(0.95);
  }
  40% {
    transform: translate3d(0, -50rem, 0) scale(0.95);
  }
  100% {
    transform: translate3d(-220%, 150%, -4000px) scale(0.95);
  }
}
@keyframes card-wrapper-movement-in {
  0% {
    transform: translate3d(-220%, 150%, -4000px) scale(0.95);
  }
  60% {
    transform: translate3d(0, -50rem, 0) scale(0.95);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(0.95);
  }
}
@keyframes card-shadowxxxxx {
  from {
    box-shadow: 0.5rem 0.5rem 2rem rgba(0, 0, 0, 0);
  }
  to {
    box-shadow: 0.5rem 0.5rem 2rem rgba(0, 0, 0, 0.5);
  }
}

.Category {
  --this-is-a: "placeholder so that linting doesn't complain";
}

.CategoryBanner {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-size: 250px 250px;
  box-shadow: 0 2em 2em -2em inset rgba(0, 0, 0, 0.5), 0 -2em 2em -2em inset rgba(0, 0, 0, 0.35);
}
.CategoryBanner img {
  box-shadow: 0.2rem 0.2rem 0.75rem rgba(0, 0, 0, 0.15), 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.25);
}
.CategoryBanner.default-decoration {
  box-shadow: 0 2em 2em -2em inset rgba(0, 0, 0, 0.5);
}
.CategoryBanner.default-decoration::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(transparent, #f3f3f7), repeating-linear-gradient(70deg, rgba(243, 243, 247, 0.5) 0, rgba(243, 243, 247, 0.5) 2px, transparent 2px, transparent 10px);
}
.CategoryBanner .text-block {
  display: inline-block;
  background-color: #f3f3f7;
  color: #1e234d;
  border-radius: 0.25rem;
  padding: 2rem 3rem;
  margin: 3rem 0;
  box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.1), 0.1rem 0.1rem 1.5rem rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .CategoryBanner .text-block {
    padding: 3rem 4rem;
  }
}
.CategoryBanner .text-block :last-child {
  margin-bottom: 0;
}

.Payment .payment-options {
  margin-bottom: 1rem;
}
.Payment .btn.payment-toggle {
  background-color: #f3f3f7;
  border: 1px solid #ced4da;
  text-transform: none;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
}
.Payment .btn.payment-toggle-active {
  background-color: #34da96;
}
.Payment .btn.payment-toggle:last-of-type {
  margin-right: 0;
}
.Payment .braintree-hosted-fields-wrapper .braintree-hosted-field {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.Payment .braintree-hosted-fields-wrapper .braintree-hosted-fields-valid,
.Payment .braintree-hosted-fields-wrapper .is-valid {
  border: 1px solid #34da96;
}
.Payment .braintree-hosted-fields-wrapper .braintree-hosted-fields-invalid,
.Payment .braintree-hosted-fields-wrapper .is-invalid {
  border: 2px solid #831d1d;
}
.Payment #checkout-message {
  color: #831d1d;
  margin: 1em 0;
}
.Payment .apple-pay-button {
  line-height: 1;
  padding: 0.75em;
}
.Payment .apple-pay-button span {
  font-size: 0.9em !important;
}
.Payment .zoid-outlet {
  width: 100% !important;
}
.Payment .payment-container {
  background: #fff;
  text-align: center;
  position: relative;
}
.Payment .payment-container.disabled {
  cursor: not-allowed;
  filter: grayscale(1);
}
.Payment .payment-container.disabled::after {
  content: "";
  background-color: rgba(243, 243, 247, 0.75);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100000;
}

.Panel.thanks-always-first {
  background-color: #a4adb8;
  text-align: center;
  padding: 1rem 0;
}

.Editable p.edit-toggle {
  margin-top: 0.5rem;
  color: #34da96;
  line-height: initial;
  font-weight: bold;
}
.Editable p.edit-toggle:hover {
  cursor: pointer;
}
.Editable .editable-data {
  flex-grow: 1;
}
.Editable .editable-button {
  margin-left: 1rem;
}
.Editable .summary {
  white-space: pre-wrap;
  display: block;
  line-height: 1.3rem;
}
.Editable input {
  padding: 0 0.5rem;
}
.Editable .form-group {
  margin-bottom: 0;
}

.PageError .error-message h1 {
  font-size: 3rem;
}
@media (min-width: 768px) {
  .PageError .error-message h1 {
    font-size: 5rem;
  }
}
@media (min-width: 1200px) {
  .PageError .error-message h1 {
    font-size: 7rem;
  }
}

.ErrorPanel {
  display: flex;
  position: relative;
  background-color: #d23434;
  overflow: hidden;
}
.ErrorPanel .content {
  padding: 1rem;
  position: absolute;
  color: #831d1d;
}
.ErrorPanel.square {
  padding-bottom: 100%;
}
.ErrorPanel.video {
  padding-bottom: 56.25%;
}

@keyframes slide-up {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
}
.GiftForm label {
  margin-bottom: 0;
  font-weight: bold;
}
.GiftForm .card .card-accordion-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.GiftForm .card .card-accordion-button svg {
  fill: #52575e;
  width: 1.5rem;
  height: 1.5rem;
}
.GiftForm .card .card-remove-section {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
}
.GiftForm .card .card-remove-button {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 1rem;
  position: relative;
  z-index: 10;
}
.GiftForm .card .card-remove-button svg {
  fill: #52575e;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #fff;
  border-radius: 50%;
  transition: opacity 300ms ease-in-out, fill 300ms ease-in-out, transform 500ms ease-in-out;
}
.GiftForm .card .card-remove-button:active svg {
  opacity: 0.8;
}
.GiftForm .card .card-remove-button:hover svg {
  fill: #831d1d;
  transform: scale(1.1) rotate(180deg);
}
.GiftForm .card .recipient-removal-confirmation {
  animation: slide-up 100ms ease-in-out;
  z-index: 10000;
  right: 0;
  top: 110%;
  position: absolute;
  word-wrap: none;
  white-space: nowrap;
  padding: 1rem;
  border: 1px solid #999;
  background-color: #ccc;
  box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.1), 0.1rem 0.1rem 1.5rem rgba(0, 0, 0, 0.1);
}
.GiftForm .card .recipient-removal-confirmation::before, .GiftForm .card .recipient-removal-confirmation::after {
  content: "";
  right: 0.75rem;
  position: absolute;
  width: 2rem;
  height: 2rem;
  border: 1rem solid transparent;
  pointer-events: none;
}
.GiftForm .card .recipient-removal-confirmation::before {
  top: calc(-2rem - 1px);
  border-bottom-color: #999;
}
.GiftForm .card .recipient-removal-confirmation::after {
  top: -2rem;
  border-bottom-color: #ccc;
}
.GiftForm .card .card-header {
  background-color: #e4e4ed;
  cursor: pointer;
}
.GiftForm .card .card-header .card-title {
  font-size: 1rem;
  padding-top: 0.5rem;
}
@media (min-width: 768px) {
  .GiftForm .card .card-header .card-title {
    flex-direction: row;
  }
}
.GiftForm .card .card-header .card-subtitle {
  padding-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: normal;
}
.GiftForm .card .fake-link {
  margin-bottom: 0.3333333333rem;
  display: block;
}

@media (max-width: 357px) {
  .GiftForm.Personalise .react-datepicker__month-container {
    width: 230px;
  }
  .GiftForm.Personalise .react-datepicker__month-container .react-datepicker__month {
    margin-left: 0;
    margin-right: 0;
  }
  .GiftForm.Personalise .react-datepicker__time-container {
    width: 80px;
  }
  .GiftForm.Personalise .react-datepicker__time-container .react-datepicker__time-box {
    width: 80px;
  }
  .GiftForm.Personalise .react-datepicker {
    left: -11px;
  }
}
.GiftForm.Personalise .Personalise__card {
  position: relative;
  border-radius: 0;
  margin-bottom: 1rem;
  border: 1px solid transparent;
}
.GiftForm.Personalise .Personalise__card.error {
  border: 1px solid var(--danger);
}
.GiftForm.Personalise .Personalise__card .card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .GiftForm.Personalise .Personalise__card .card-header {
    flex-direction: row;
  }
}
.GiftForm.Personalise .Personalise__card .card-header:not(.show) {
  display: none;
}
.GiftForm.Personalise .Personalise__card .card-body {
  background-color: #e4e4ed;
}
@media (min-width: 768px) {
  .GiftForm.Personalise .Personalise__card .card-body {
    padding-top: 3rem;
  }
}

.GiftForm.Donation .donation-option {
  cursor: pointer;
  text-align: center;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: height 300ms ease-in-out, background-color 300ms ease-in-out, box-shadow 300ms ease-in-out;
}
.GiftForm.Donation .donation-option:hover {
  background-color: #fff;
  box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.1), 0.1rem 0.1rem 1.5rem rgba(0, 0, 0, 0.1);
}
.GiftForm.Donation .donation-option input {
  border: 0;
  text-align: center;
  margin-top: 1rem;
  width: 100%;
  font-size: 1.5em;
  font-weight: 700;
  padding-right: 1em;
}
.GiftForm.Donation .donation-option .input-wrapper {
  position: relative;
}
.GiftForm.Donation .donation-option .input-icon {
  position: absolute;
  right: 3px;
  top: calc(50% - 0.5em); /* Keep icon in center of input, regardless of the input height */
  font-size: 1.5rem;
  display: none;
}
.GiftForm.Donation .donation-option p {
  margin: 0;
}
.GiftForm.Donation .donation-option .btn {
  pointer-events: none;
}
.GiftForm.Donation .gift-aid-section {
  padding: 2rem 1rem;
  border: 2px solid;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.GiftForm.Donation .gift-aid-section img.gift-aid {
  width: 10em;
}
.GiftForm.Donation .gift-aid-text {
  cursor: pointer;
  text-decoration: underline;
  color: #34da96;
  font-weight: bold;
}
.GiftForm.Donation .gift-aid-details {
  background-color: #a4adb8;
  padding: 1rem;
  margin-bottom: 2rem;
}
.GiftForm.Donation .gift-aid-details .gift-aid-text {
  color: #000;
  font-weight: bold;
  margin-bottom: 0;
}
.GiftForm.Donation .gift-aid-data-capture input {
  display: block;
}
.GiftForm.Donation .active .donation-option {
  background-color: #34da96;
}
.GiftForm.Donation input[type=number]::-webkit-inner-spin-button,
.GiftForm.Donation input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.GiftForm.Donation .form-control-sm {
  height: calc(1.3em + 0.15rem + 2px) !important;
  padding: 0 !important;
  font-size: 1rem;
  text-align: center;
  border: 0;
  background-color: #f3f3f7 !important;
  color: #34da96 !important;
}
.GiftForm.Donation .form-control-sm:focus {
  box-shadow: none;
}
.GiftForm.Donation .form-control-sm:hover {
  cursor: pointer !important;
}
.GiftForm.Donation label.btn.btn-primary {
  margin-bottom: 0;
  background-color: #f3f3f7;
  color: #34da96;
  text-transform: uppercase;
  font-weight: bold;
}
.GiftForm.Donation label.btn.btn-primary.active {
  background-color: #34da96;
}
.GiftForm.Donation label.btn.btn-primary:hover, .GiftForm.Donation label.btn.btn-primary:active {
  color: #f3f3f7;
  background-color: #34da96;
}
.GiftForm.Donation h5 span {
  color: #34da96;
  font-weight: bold;
}

.GiftForm.Preview hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.GiftForm.Preview .Preview__card {
  margin-bottom: 1rem;
}
.GiftForm.Preview .Preview__card .form-label {
  font-weight: bold;
}
.GiftForm.Preview .Preview__card .card-body {
  background-color: #f3f3f7;
}
.GiftForm.Preview .Preview__card.is-active .card-header {
  background-color: #cec;
}
.GiftForm.Preview .Preview__donation-details ul {
  list-style-type: none;
  padding: 0;
}

.GiftForm.SenderDetailsAndPayment .icon {
  width: 1rem;
}
.GiftForm.SenderDetailsAndPayment .form-check-input {
  margin-top: 0;
}
.GiftForm.SenderDetailsAndPayment .payment-card {
  display: inline-flex;
  align-items: center;
  padding: 0 1rem;
}
.GiftForm.SenderDetailsAndPayment .payment-card .payment-icon {
  width: 4rem;
}
.GiftForm.SenderDetailsAndPayment .contact-opt-in-prompt {
  border: 2px solid #34da96;
  padding: 1rem;
  margin-bottom: 2rem;
}

.btn-outline-primary {
  border-color: #34da96;
  background-color: #f3f3f7;
  color: #52575e;
}

.btn-outline-primary:hover {
  background-color: #1a8e5f !important;
  border-color: #1a8e5f !important;
  color: #f3f3f7;
}

.Gift {
  padding: 1rem;
  box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.1), 0.1rem 0.1rem 1.5rem rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}
.Gift .title {
  font-size: 1.5em;
  font-weight: 700;
  color: #52575e;
  margin: 0;
}
.Gift .details :last-of-type {
  margin-bottom: 0;
}

.HomeBanner {
  background-color: #f3f3f7;
}
.HomeBanner img.homepage-decoration-image {
  width: 100%;
  max-height: 10rem;
  object-fit: contain;
}
.HomeBanner h1 {
  color: #1e234d;
}

.LoadingPlaceholder {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.LoadingPlaceholder img {
  max-width: 4rem;
  animation: stretch 1s infinite alternate ease-in-out;
}
.LoadingPlaceholder span {
  font-size: 0.75em;
  display: block;
  color: #caa;
}

@keyframes stretch {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.9);
  }
}
.Order {
  margin-bottom: 4rem;
}

.Page {
  background-color: #fafaf7;
}

.ProductMessage {
  position: absolute;
  width: 100%;
  height: 100%;
  white-space: pre-wrap;
  box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.1), 0.1rem 0.1rem 1.5rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  --container-width: 300px;
}
.preview-mode-button .ProductMessage {
  --container-width: var(--preview-mode-button-container-width);
}
.ProductMessage .gift-display {
  max-height: 30%;
  overflow: hidden;
  background-color: #dedede;
  padding: calc(var(--container-width) / 15);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(var(--container-width) / 30);
}
.ProductMessage .gift-display img {
  width: calc(var(--container-width) / 5);
}
.ProductMessage .gift-display p {
  font-family: "Libre Franklin", "Droid Sans", "Helvetica Neue", sans-serif;
  margin-left: calc(var(--container-width) / 26);
  margin-bottom: 0;
  text-align: left;
}
.ProductMessage .message-outer {
  background-image: linear-gradient(#fefefe, #dedede);
  background-color: #dedede;
  position: relative;
  width: auto;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-size: 1rem;
  font-size: calc(var(--container-width) / 25);
}
.ProductMessage .message-outer::before, .ProductMessage .message-outer::after {
  content: "";
  position: absolute;
  display: block;
  z-index: 10;
  width: 100%;
  height: calc(var(--container-width) / 25);
}
.ProductMessage .message-outer::before {
  background-image: linear-gradient(to bottom, #fefefe, transparent);
  top: 0;
}
.ProductMessage .message-outer::after {
  background-image: linear-gradient(to top, #dedede, transparent);
  bottom: 0;
}
.ProductMessage .message-outer .message-inner {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ProductMessage .message-outer p {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  max-height: 100%;
  font-family: "Libre Baskerville", Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;
  padding: calc(var(--container-width) / 25);
  margin: 0;
}

.ProductModal h2 {
  font-size: 1.5rem;
}
@media (min-width: 575px) {
  .ProductModal h2 {
    font-size: 2rem;
  }
}
.ProductModal .checklist {
  font-size: 0.9rem;
}
@media (min-width: 385px) {
  .ProductModal .checklist {
    font-size: 1.25rem;
  }
}
.ProductModal img.image {
  width: 100%;
}
.ProductModal .close {
  font-size: 3rem !important;
  color: #1e234d !important;
  padding: 0.25rem 1rem;
  opacity: 1;
}

.ProductPreview {
  position: relative;
}
.ProductPreview .preview-wrapper-outer {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}
.ProductPreview .preview-wrapper-inner {
  position: absolute;
  width: 100%;
  height: 100%;
}
.ProductPreview .preview-mode-button {
  width: 25%;
  margin: 1rem;
  text-align: center;
  cursor: pointer;
  box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.1), 0.1rem 0.1rem 1.5rem rgba(0, 0, 0, 0.1);
  opacity: 0.5;
  transition: box-shadow 200ms ease-in-out, opacity 200ms ease-in-out;
  position: relative;
}
.ProductPreview .preview-mode-button-icon {
  position: relative;
  height: 100%;
}
.ProductPreview .preview-mode-button .boost-to-scale {
  width: 400%;
  height: 400%;
  transform-origin: 0 0;
  transform: scale(0.25);
}
.ProductPreview .preview-mode-button .unwrapping-inset {
  position: absolute;
  width: 70%;
  left: 20%;
  top: 20%;
}
.ProductPreview .preview-mode-button .unwrapping-play-button {
  opacity: 0.75;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: opacity 100ms ease-in-out;
}
.ProductPreview .preview-mode-button:hover {
  opacity: 1;
}
.ProductPreview .preview-mode-button:hover .unwrapping-play-button {
  opacity: 1;
}
.ProductPreview .preview-mode-button::after {
  content: "";
  pointer-events: none;
  position: absolute;
  display: block;
  width: 50%;
  height: 0.1rem;
  z-index: 20;
  border-radius: 1rem;
  transform: translate(-50%, -50%);
  left: 50%;
  bottom: -0.5rem;
  background-color: #1e234d;
  transition: opacity 200ms ease-in-out;
  opacity: 0;
}
.ProductPreview .preview-mode-button.active {
  opacity: 1;
  box-shadow: 0.2rem 0.2rem 0.75rem rgba(0, 0, 0, 0.15), 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.25);
  pointer-events: none;
}
.ProductPreview .preview-mode-button.active::after {
  opacity: 1;
}

.ProductThumbnail {
  position: relative;
  animation-duration: 250ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  text-align: center;
}
.ProductList .ProductThumbnail {
  animation-name: product-fade-in;
  margin-bottom: 2rem;
}
.ProductThumbnail img {
  width: 100%;
  box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.25);
  transition: box-shadow 250ms ease-in-out;
}
.ProductThumbnail.link {
  cursor: pointer;
}
.ProductThumbnail.link:hover img.image {
  box-shadow: 0.2rem 0.2rem 0.75rem rgba(0, 0, 0, 0.5);
}
.ProductThumbnail.link button {
  margin-top: 1rem;
}
@media (min-width: 576px) {
  .ProductThumbnail button.gift-select {
    display: none;
  }
}

@keyframes product-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.ProductFull {
  position: relative;
  text-align: center;
}
.ProductFull img {
  display: block;
  width: 100%;
  aspect-ratio: 1;
  box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.25);
  transition: box-shadow 250ms ease-in-out;
}
.ProductFull img::before {
  background-color: #cccccc;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background-image: linear-gradient(135deg, #eeeeee 40%, #dddddd 40%, #dddddd 50%, #eeeeee 50%, #eeeeee 90%, #dddddd 90%, #dddddd 100%);
  background-size: 20px 20px;
}
.ProductFull img::after {
  content: "☹" ' MISSING IMAGE \a\a(Should be: "' attr(alt) '")';
  color: #333;
  white-space: pre-wrap;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: space-around;
  position: absolute;
}

.ProductList {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

a {
  color: #1e234d;
}

.btn.load-more {
  animation-delay: 1000ms;
  animation-duration: 250ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: product-fade-in;
  margin-bottom: 4rem;
}

input[name=turnstile] {
  display: none;
}

.SiteContent {
  flex-grow: 1;
}

.StandardPanel {
  margin-top: 4rem;
  margin-bottom: 8rem;
}
.StandardPanel img {
  width: 100%;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .StandardPanel img {
    margin-bottom: 0;
  }
}

.PageCreate h3 {
  color: #34da96;
  font-size: 0.8rem;
}
.PageCreate hr {
  border: 1px solid #52575e;
  width: 100%;
}
.PageCreate .react-datepicker-wrapper {
  display: block;
}
.PageCreate .react-datepicker-wrapper input {
  width: 100%;
  padding: 0.5rem;
  color: #6c757d;
  font-size: 0.9rem;
}
@media (min-width: 375px) {
  .PageCreate h3 {
    font-size: 1rem;
  }
}
@media (min-width: 992px) {
  .PageCreate h3 {
    font-size: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .PageCreate h3 {
    font-size: 1.75rem;
  }
}
.PageCreate__title {
  text-align: center;
  overflow: hidden;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.PageCreate__title h3 {
  display: block;
  color: #f3f3f7;
}
.PageCreate__container {
  background-color: #34da96;
}
.PageCreate__help {
  display: block;
  color: #f3f3f7;
  font-weight: bold;
  font-size: 0.8rem;
}
@media (min-width: 992px) {
  .PageCreate__help {
    font-size: 1rem;
  }
}

.PageGift h2 {
  color: #34da96;
}
.PageGift div.message {
  background-color: #f3f3f7;
}
.PageGift div.giftFrom {
  font-style: italic;
}
.PageGift img.icon {
  width: 3rem;
  margin: 0.5rem;
}
@media (min-width: 768px) {
  .PageGift img.icon {
    width: 2rem;
  }
}
.PageGift .giftpage-icon {
  width: 10rem;
}
@media (min-width: 768px) {
  .PageGift .giftpage-icon {
    width: 6rem;
  }
}

.PageThanks .social-links {
  text-align: center;
  background-color: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.PageThanks img.icon {
  width: 3rem;
  margin: 0.5rem;
}
@media (min-width: 768px) {
  .PageThanks img.icon {
    width: 2rem;
  }
}
.PageThanks table.order-details {
  margin-bottom: 2rem;
  font-size: 0.9em;
}
.PageThanks table.order-details tr {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.PageThanks table.order-details td {
  padding: 0.25rem 0.5rem;
  vertical-align: top;
}

.PlatformFeatures .text {
  color: #1e234d;
  text-align: center;
}
.PlatformFeatures .text h3 {
  font-size: 1.25rem;
  text-align: center;
}
.PlatformFeatures .icon {
  justify-content: center;
}
.PlatformFeatures .icon img {
  width: 10em;
  height: auto;
  margin-bottom: 0.5rem;
}

.AboutUs hr {
  background-color: #34da96;
  width: 90%;
  height: 1px;
}
.AboutUs button a {
  color: #f3f3f7;
}
.AboutUs button a:hover {
  text-decoration: none;
}

.fake-link {
  color: #34da96;
  font-weight: 700;
  cursor: pointer;
  display: inline-block;
}
.fake-link:hover {
  text-decoration: underline;
}
